import React from 'react';
import { useTheme } from '@components/theme';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import CarouselNavigator from '@components/shared/carousels/CarouselNavigator/CarouselNavigator';
import { times } from 'lodash';
import breakpoints from '@constants/breakpoints';
import Star from './star.svg';

import styles from './Reviews.module.scss';

SwiperCore.use([Autoplay, Navigation]);

export interface IReview {
  author: string;
  stars: number;
  text: string;
  title: string;
}

function ReviewCard({
  text, title, stars, author
}: IReview) {
  const theme = useTheme();
  return (
    <div style={{ background: theme.colors.core2 }} className={styles.Card}>
      <div className={styles.Author}>by {author} </div>
      <div className={styles.Stars}>{times(stars).map((iter) => (
        <span key={iter}>
          <Star />
        </span>
      ))}
      </div>
      <div className={classNames(theme.styles.Title3, styles.ReviewTitle)}>{title}</div>
      <div className={styles.Description}>
        <span>
          {text}
        </span>
      </div>
    </div>
  );
}

interface IReviewProps {
  className?: string;
  title?: string;
  subtitle?: string;
  cta?: string;
  ctaLink?: string;
  reviews: IReview[];
  newHomepage?: boolean;
}

const Reviews = ({
  className, title, subtitle, cta, ctaLink, reviews, newHomepage
}: IReviewProps) => {
  const theme = useTheme();
  const titleClass = classNames(theme.styles.Header, styles.Title);
  const isNewHomeClass = newHomepage ? styles.NewHome : '';
  const finalSubTitle = newHomepage ? 'Over 100,000 designs completed for happy clients.' : subtitle;

  return (
    <section
      className={classNames(styles.Container, className, isNewHomeClass)}
    >
      <div className={styles.Wrapper}>
        <div className={styles.Left}>
          {title && (
            <div
              className={classNames(
                theme.styles.Overline,
                styles.Overline
              )}
            >
              {title}
            </div>
          )}

          {subtitle && (
            <h2 className={titleClass}>
              {finalSubTitle}
            </h2>
          )}

          {!!cta && (
            <div>
              <a
                className={classNames(theme.styles.Button,
                  theme.styles.Primary,
                  theme.styles.Conflicting,
                  styles.Cta)}
                href={ctaLink || `/reviews`}
              >
                {cta}
              </a>
            </div>
          )}

        </div>

        <div className={styles.Right}>
          {newHomepage ? (
            <Swiper
              centeredSlides
              loop
              loopedSlides={50}
              slidesPerView="auto"
              spaceBetween={12}
              className={styles.Combo}
              navigation={{
                nextEl: '.swiper-next',
                prevEl: '.swiper-previous'
              }}
              breakpoints={{
                [breakpoints['large-tablet']]: {
                  centeredSlides: false,
                  spaceBetween: 40
                }
              }}
            >
              {reviews.map((review: IReview) => (
                <SwiperSlide key={review.author + review.title} className={styles.Item}>
                  <ReviewCard {...review} />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Swiper
              centeredSlides
              loop
              loopedSlides={50}
              slidesPerView="auto"
              spaceBetween={12}
              className={styles.Combo}
              navigation={{
                nextEl: '.swiper-next',
                prevEl: '.swiper-previous'
              }}
              breakpoints={{
                [breakpoints['large-tablet']]: {
                  centeredSlides: false,
                  spaceBetween: 40
                }
              }}
            >
              {reviews.map((review: IReview) => (
                <SwiperSlide key={review.author + review.title} className={styles.Item}>
                  <ReviewCard {...review} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}

        </div>

      </div>

      <CarouselNavigator className={styles.Pagination} navNext="swiper-next" navPrev="swiper-previous" />
    </section>
  );
};

export default Reviews;
